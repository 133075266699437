import React from 'react';
import {Bestuurslid} from "./Bestuurslid";
import fotoBestuur1 from "./img/bestuur1.jpg"
import fotoBestuur2 from "./img/bestuur2.jpg"
import fotoBestuur3 from "./img/bestuur3.jpg"
import fotoBestuur4 from "./img/bestuur4.jpg"
import fotoBestuur5 from "./img/bestuur5.png"
import fotoBestuur6 from "./img/bestuur6.jpg"
import fotoBestuur7 from "./img/bestuur7.png"
import './bestuur.css';

export class Bestuur extends React.Component {
  constructor(){
    super()
    this.state= {
      Bestuur1: {Naam: "Jasper Symons", Functie: "Voorzitter, Seniorcoördinator", Telefoon: "0498/41.06.05", Email: "jasper-symons@hotmail.com"},
      Bestuur2: {Naam: "Trees Peeters", Functie: "Secretaris", Telefoon: "0473/70.90.14", Email: "trees.peeters@skynet.com"},
      Bestuur3: {Naam: "Lobke Geeraerts", Functie: "Penningmeester", Telefoon: "0477/54.90.84", Email: "lobke_geeraerts@hotmail.com"},
      Bestuur4: {Naam: "Ines Van den Wyngaert", Functie: "Communicatie", Telefoon: "0478/43.34.40", Email: "inesvdwijngaert@hotmail.com"},
      Bestuur5: {Naam: "Robbe Calant", Functie: "Jeugdcoördinator", Telefoon: "0491/32.88.25", Email: "robbe-calant@hotmail.com"},
      Bestuur6: {Naam: "An Verschueren", Functie: "Vertegenwoordigster van de ouders, aanspreekpunt integriteit (API)", Telefoon: "0494/54.75.80", Email: "anverschueren.wfm@gmail.com"},
      Bestuur7: {Naam: "Jij misschien?", Functie: "", Telefoon: "", Email: ""}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="backgroundBestuur"></div>
        <div className="white-bg"></div>
          <h1 className="activiteit-titel">Bestuur</h1>
          <div className="onderlijn"></div>
          <div className="bestuurLijst">
            <Bestuurslid img={fotoBestuur1} naam={this.state.Bestuur1.Naam} functie={this.state.Bestuur1.Functie} gsm={this.state.Bestuur1.Telefoon} email={this.state.Bestuur1.Email}/>
            <Bestuurslid img={fotoBestuur2} naam={this.state.Bestuur2.Naam} functie={this.state.Bestuur2.Functie} gsm={this.state.Bestuur2.Telefoon} email={this.state.Bestuur2.Email}/>
            <Bestuurslid img={fotoBestuur3} naam={this.state.Bestuur3.Naam} functie={this.state.Bestuur3.Functie} gsm={this.state.Bestuur3.Telefoon} email={this.state.Bestuur3.Email}/>
            <Bestuurslid img={fotoBestuur4} naam={this.state.Bestuur4.Naam} functie={this.state.Bestuur4.Functie} gsm={this.state.Bestuur4.Telefoon} email={this.state.Bestuur4.Email}/>
            <Bestuurslid img={fotoBestuur5} naam={this.state.Bestuur5.Naam} functie={this.state.Bestuur5.Functie} gsm={this.state.Bestuur5.Telefoon} email={this.state.Bestuur5.Email}/>
            <Bestuurslid img={fotoBestuur6} naam={this.state.Bestuur6.Naam} functie={this.state.Bestuur6.Functie} gsm={this.state.Bestuur6.Telefoon} email={this.state.Bestuur6.Email}/>
            <Bestuurslid img={fotoBestuur7} naam={this.state.Bestuur7.Naam} functie={this.state.Bestuur7.Functie} gsm={this.state.Bestuur7.Telefoon} email={this.state.Bestuur7.Email}/>
          </div>
      </div>
    )
  }
}
